






































































































































import { Component, Vue, Ref } from "vue-property-decorator";

import AbTestConfigurationModel, {
  AbTestType,
} from "../models/AbTestConfigurationModel";
import ValidUtil from "@/shared/utils/ValidUtil";
import ConfigUtil from "../utils/ConfigUtil";
import { VueForm } from "@/shared/types/ExtendedVueType";

@Component
export default class AbTestEnablingDialog extends Vue {
  @Ref("form") readonly form!: VueForm;

  isMenuActiveSinceVisible = false;
  isMenuActiveTillVisible = false;
  isMenuAssignmentTillVisible = false;
  activeSince = "";
  activeTill = "";
  assigmentTillDate = "";
  isValid = true;

  get abTest(): AbTestConfigurationModel {
    return this.$store.state.abTestConfig.enablingAbTest;
  }

  get isDialogVisible(): boolean {
    return !!this.$store.state.abTestConfig.enablingAbTest;
  }

  set isDialogVisible(newValue: boolean) {
    if (newValue) {
      return;
    }

    this.hideDialog();
  }

  get currentDate(): string {
    return ConfigUtil.getCurrentDate();
  }

  get isNewUsersType(): boolean {
    return this.abTest.abTestType === AbTestType.NEW_USERS;
  }

  get rulesActiveSince() {
    return [
      ...(this.isNewUsersType || this.abTest.activeSince
        ? [ValidUtil.required(this.$lang("validation.required"))]
        : []),
    ];
  }

  hideDialog() {
    this.$store.commit("setEnablingAbTest");
  }

  enableAbTest() {
    if (!this.form.validate()) {
      return;
    }

    this.$store
      .dispatch("enableAbTest", {
        abTest: this.abTest,
        date: {
          activeSince: this.activeSince || null,
          activeTill: this.activeTill || null,
          assigmentTillDate: this.assigmentTillDate || null,
        },
      })
      .then(() => {
        this.hideDialog();
      });
  }
}
