








import { Component, Vue, Watch } from "vue-property-decorator";
import { Route } from "vue-router";

import { DictionaryType } from "@/shared/models";
import AbTestDisablingDialog from "@/ab-tests/components/AbTestDisablingDialog.vue";
import AbTestEnablingDialog from "@/ab-tests/components/AbTestEnablingDialog.vue";
import { ConfigsStatus } from "../models/ConfigsStatusModel";

@Component({
  components: {
    AbTestDisablingDialog,
    AbTestEnablingDialog,
  },
})
export default class AbTests extends Vue {
  checkedConfigStatuses: Array<ConfigsStatus> = [];

  get applicationId(): string {
    return this.$route.params.id;
  }

  get isEnablingDialogVisible(): boolean {
    return !!this.$store.state.abTestConfig.enablingAbTest;
  }

  get isDisablingDialogVisible(): boolean {
    return !!this.$store.state.abTestConfig.disablingAbTest;
  }

  @Watch("applicationId", { immediate: true })
  async watchApplicationId() {
    await Promise.all([
      this.$store.dispatch("loadDefaultConfig"),
      this.$store.dispatch("loadDictionaries", {
        app: this.applicationId,
        dictionaryTypes: [
          DictionaryType.LANGUAGES,
          DictionaryType.COUNTRIES,
          DictionaryType.DEVICE_TYPES,
          DictionaryType.DEVICE_MODELS,
          DictionaryType.APP_VERSIONS,
          DictionaryType.OS_VERSIONS,
          DictionaryType.SEGMENTS,
          DictionaryType.AB_TEST_DAY_NUMBER,
        ],
      }),
    ]);
  }

  @Watch("$route", { deep: true, immediate: true })
  private watchRoute(newRoute: Route, oldRoute: Route | undefined) {
    if (newRoute.name?.includes("abTest")) {
      document.title = this.$lang(
        "documentTitle",
        this.$lang("abTestConfig.title")
      );
    } else if (newRoute.name?.includes("external")) {
      document.title = this.$lang(
        "documentTitle",
        this.$lang("externalTest.title")
      );
    } else if (newRoute.name?.includes("targeted")) {
      document.title = this.$lang(
        "documentTitle",
        this.$lang("targetedConfig.title")
      );
    } else {
      document.title = this.$lang(
        "documentTitle",
        this.$lang("defaultConfig.title")
      );
    }

    if (!oldRoute) {
      return;
    }

    if (oldRoute?.name?.includes("abTest") && newRoute.name === "targeted") {
      this.checkedConfigStatuses = [
        ConfigsStatus.INIT,
        ConfigsStatus.SCHEDULED,
        ConfigsStatus.STARTED,
        ConfigsStatus.CONFIG_DISTRIBUTION_FINISHED,
      ];
    } else if (
      oldRoute?.name?.includes("targeted") &&
      newRoute.name === "abTest"
    ) {
      this.checkedConfigStatuses = [
        ConfigsStatus.INIT,
        ConfigsStatus.SCHEDULED,
        ConfigsStatus.STARTED,
        ConfigsStatus.GROUPS_DISTRIBUTED,
        ConfigsStatus.CONFIG_DISTRIBUTION_FINISHED,
        ConfigsStatus.STATISTICS_RECALCULATION,
        ConfigsStatus.STATISTICS_RECALCULATED,
      ];
    }
  }

  created() {
    if (this.$route.name === "targeted") {
      this.checkedConfigStatuses = [
        ConfigsStatus.INIT,
        ConfigsStatus.SCHEDULED,
        ConfigsStatus.STARTED,
        ConfigsStatus.CONFIG_DISTRIBUTION_FINISHED,
      ];
    } else {
      this.checkedConfigStatuses = [
        ConfigsStatus.INIT,
        ConfigsStatus.SCHEDULED,
        ConfigsStatus.STARTED,
        ConfigsStatus.GROUPS_DISTRIBUTED,
        ConfigsStatus.CONFIG_DISTRIBUTION_FINISHED,
        ConfigsStatus.STATISTICS_RECALCULATION,
        ConfigsStatus.STATISTICS_RECALCULATED,
      ];
    }
  }
}
