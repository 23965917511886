






















import { Component, Vue } from "vue-property-decorator";

import AbTestConfigurationModel from "@/ab-tests/models/AbTestConfigurationModel";

@Component
export default class AbTestDisablingDialog extends Vue {
  get abTest(): AbTestConfigurationModel {
    return this.$store.state.abTestConfig.disablingAbTest;
  }

  get isDialogVisible(): boolean {
    return !!this.$store.state.abTestConfig.disablingAbTest;
  }

  set isDialogVisible(newValue: boolean) {
    if (newValue) {
      return;
    }

    this.hideDialog();
  }

  hideDialog() {
    this.$store.commit("setDisablingAbTest");
  }

  disableAbTest() {
    this.$store.dispatch("disableAbTest", {
      abTest: this.abTest,
      confirmed: true,
    });
  }
}
